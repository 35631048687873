import { postIndberetning } from "api/stednavn";
import ValidationErrorMessage from "components/common/FormElements/ValidationErrorMessage";
import AccepterVilkår from "components/common/Indberet/AccepterVilkaar";
import EmailInput from "components/common/Indberet/EmailInput";
import { ModalContext } from "components/common/Modal/Modal";
import { finishedDrawingSource } from "config/interactions";
import { AuthContext } from "context/AuthProvider";
import { MapContext } from "context/MapProvider";
import { ToastContext } from "context/ToastProvider";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { useHistory } from "react-router";
import { routes } from "routes/routesConfig";
import FindStednavn from "../components/FindStednavn";
import Kommentar from "../components/Kommentar";
import Tilbage from "../components/Tilbage";
import { NyIndberetningState } from "../StednavnIndberet";
import { prepareDeleteStednavnSag } from "../util";
import { SearchResult } from "interfaces/IGSearch";
import { GeoJSON } from "ol/format";

export default function DeleteStednavn() {
  const history = useHistory();
  const { authState, setCurrentUserEmail } = useContext(AuthContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const toast = useContext(ToastContext);
  const findStednavnRef = useRef<HTMLDivElement>(null);
  const kommentarRef = useRef<HTMLDivElement>(null);
  const emailRef = useRef<HTMLLabelElement>(null);
  useEffect(() => {
    return () => {
      finishedDrawingSource.clear();
    };
  }, []);
  const {
    // dispatchToMap,
    mapState,
  } = useContext(MapContext);
  const { map } = mapState;
  const [searchResult, setSearchResult] = useState<SearchResult | undefined>(
    undefined
  );
  const indberetningReducer = (
    state: NyIndberetningState,
    action:
      | { type: "SET_ALL_TOUCHED" }
      | { type: "SET_NYTSTEDNAVN"; navn: string }
      | { type: "SET_VILKAAR_ACCEPTERET"; vilkårAccepteret: boolean }
      | { type: "SET_BESKRIVELSE"; beskrivelse: string }
      | { type: "SET_EMAIL"; email: string }
      | { type: "SET_SEARCHRESULT"; searchResult: SearchResult | undefined }
      | { type: "RESET_NOTIFY_BESKRIVELSE" }
      | { type: "RESET_NOTIFY_EMAIL" }
      | { type: "RESET_NOTIFY_EMNE" }
      | { type: "RESET_NOTIFY_FILELLERGEOMETRI" }
      | { type: "RESET_NOTIFY_NYTSTEDNAVN" }
      | { type: "RESET_NOTIFY_VILKAAR_ACCEPTERET" }
      | { type: "RESET_NOTIFY_ÆNDRINGSTYPE" }
  ): NyIndberetningState => {
    switch (action.type) {
      case "SET_ALL_TOUCHED": {
        const validation = { ...state.validation };
        Object.entries(validation).forEach(([key, v]) => {
          v.touched = true;
        });
        return { ...state, validation };
      }
      case "SET_VILKAAR_ACCEPTERET": {
        return {
          ...state,
          vilkårAccepteret: action.vilkårAccepteret,
          validation: {
            ...state.validation,
            vilkårAccepteret: {
              ...state.validation.vilkårAccepteret,
              valid: action.vilkårAccepteret,
              touched: true,
            },
          },
        };
      }
      case "SET_BESKRIVELSE": {
        const i: NyIndberetningState = {
          ...state,
          beskrivelse: action.beskrivelse,
          validation: {
            ...state.validation,
            beskrivelse: {
              ...state.validation.beskrivelse,
              valid: action.beskrivelse ? true : false,
              touched: true,
            },
          },
        };
        return i;
      }
      case "SET_EMAIL": {
        const i: NyIndberetningState = {
          ...state,
          email: action.email,
          validation: {
            ...state.validation,
            email: {
              ...state.validation.email,
              valid: action.email ? true : false,
              touched: true,
            },
          },
        };
        return i;
      }
      case "SET_NYTSTEDNAVN": {
        const i: NyIndberetningState = {
          ...state,
          nytStednavn: action.navn,
          validation: {
            ...state.validation,
            nytStednavn: {
              ...state.validation.nytStednavn,
              valid: action.navn ? true : false,
              touched: true,
            },
          },
        };
        return i;
      }
      case "SET_SEARCHRESULT": {
        const { searchResult } = action;
        if (searchResult) {
          const feature = new GeoJSON().readFeature(searchResult.geometri);
          feature.set("visningstekst", searchResult.visningstekst);
          feature.set("type", searchResult.type);
          finishedDrawingSource.clear();
          finishedDrawingSource.addFeature(feature);
          const i: NyIndberetningState = {
            ...state,
            geometri: feature,
            emne: `Udgået stednavn - ${searchResult.visningstekst}`,
            validation: {
              ...state.validation,
              filEllerGeometri: {
                ...state.validation.nytStednavn,
                valid: true,
                touched: true,
              },
              emne: { ...state.validation.emne, touched: true, valid: true },
            },
          };
          return i;
        } else {
          finishedDrawingSource.clear();
          const i: NyIndberetningState = {
            ...state,
            geometri: undefined,
            validation: {
              ...state.validation,
              filEllerGeometri: {
                ...state.validation.nytStednavn,
                valid: state.fil ? true : false,
              },
              emne: { ...state.validation.emne, valid: false },
            },
          };
          return i;
        }
      }
      case "RESET_NOTIFY_BESKRIVELSE": {
        return {
          ...state,
          validation: {
            ...state.validation,
            beskrivelse: { ...state.validation.beskrivelse, notify: false },
          },
        };
      }
      case "RESET_NOTIFY_EMAIL": {
        return {
          ...state,
          validation: {
            ...state.validation,
            email: { ...state.validation.email, notify: false },
          },
        };
      }
      case "RESET_NOTIFY_EMNE": {
        return {
          ...state,
          validation: {
            ...state.validation,
            emne: { ...state.validation.emne, notify: false },
          },
        };
      }
      case "RESET_NOTIFY_FILELLERGEOMETRI": {
        return {
          ...state,
          validation: {
            ...state.validation,
            filEllerGeometri: {
              ...state.validation.filEllerGeometri,
              notify: false,
            },
          },
        };
      }
      case "RESET_NOTIFY_NYTSTEDNAVN": {
        return {
          ...state,
          validation: {
            ...state.validation,
            nytStednavn: { ...state.validation.nytStednavn, notify: false },
          },
        };
      }
      case "RESET_NOTIFY_VILKAAR_ACCEPTERET": {
        return {
          ...state,
          validation: {
            ...state.validation,
            vilkårAccepteret: {
              ...state.validation.vilkårAccepteret,
              notify: false,
            },
          },
        };
      }
      case "RESET_NOTIFY_ÆNDRINGSTYPE": {
        return {
          ...state,
          validation: {
            ...state.validation,
            ændringstype: { ...state.validation.ændringstype, notify: false },
          },
        };
      }
    }
  };
  const initialState: NyIndberetningState = {
    ændringstype: undefined,
    vilkårAccepteret: false,
    nytStednavn: "",
    beskrivelse: "",
    geometri: undefined,
    fil: undefined,
    email: "",
    emne: "",
    validation: {
      ændringstype: {
        valid: false,
        message: "",
        touched: false,
        notify: false,
      },
      nytStednavn: {
        valid: false,
        message: "Stednavn skal udfyldes",
        touched: false,
        notify: false,
      },
      emne: {
        valid: false,
        message: "Vælg et eksisterende stednavn ved at søge",
        touched: false,
        notify: false,
      },
      vilkårAccepteret: {
        valid: false,
        message: "Betingelserne skal være accepteret",
        touched: false,
        notify: false,
      },
      beskrivelse: {
        valid: false,
        message: "Beskrivelse skal udfyldes",
        touched: false,
        notify: false,
      },
      filEllerGeometri: {
        valid: false,
        message: "Indberetning skal indeholde mindst én fil eller geometri",
        touched: false,
        notify: false,
      },
      email: {
        valid: false,
        message: "Email skal udfyldes",
        touched: false,
        notify: false,
      },
    },
  };
  const [indberetning, setIndberetning] = useReducer(
    indberetningReducer,
    initialState
  );

  // Set email when authstate is ready
  useEffect(() => {
    const userEmail = authState.currentUser.email;
    if (userEmail && userEmail !== indberetning.email) {
      setIndberetning({ type: "SET_EMAIL", email: userEmail });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  useEffect(() => {
    setIndberetning({
      type: "SET_SEARCHRESULT",
      searchResult,
    });

    return () => { };
  }, [searchResult]);
  return (
    <div>
      <div className="mt-5">
        <Tilbage />
        <h1>Nedlæggelse af stednavn</h1>
        <p className="lead fw-semibold text-dark">
          Foreslå nedlæggelse af et eksisterende stednavn
        </p>
        <div
          className={
            indberetning.validation.emne.touched &&
              !indberetning.validation.emne.valid
              ? "l-section-wrapper form-control is-invalid "
              : "l-section-wrapper"
          }
          ref={findStednavnRef}
        >
          <FindStednavn
            map={map}
            searchResult={searchResult}
            setSearchResult={setSearchResult}
            ændringstype="nedlæg"
          />
        </div>
        <ValidationErrorMessage
          show={
            indberetning.validation.emne.touched &&
            !indberetning.validation.emne.valid
          }
          message={indberetning.validation.emne.message}
          notify={indberetning.validation.emne.notify}
          resetNotify={() => setIndberetning({ type: "RESET_NOTIFY_EMNE" })}
        />
        <div className="l-section" ref={kommentarRef}>
          <h2>Kommentar til nedlægning af stednavn</h2>
          <p>Giv en kort forklaring på, hvorfor stednavnet skal nedlægges. </p>
          <Kommentar
            notify={indberetning.validation.beskrivelse.notify}
            resetNotify={() =>
              setIndberetning({
                type: "RESET_NOTIFY_BESKRIVELSE",
              })
            }
            showValidationWarning={
              indberetning.validation.beskrivelse.touched &&
              !indberetning.validation.beskrivelse.valid
            }
            validationMessage={indberetning.validation.beskrivelse.message}
            kommentar={indberetning.beskrivelse}
            setKommentar={(kommentar) =>
              setIndberetning({
                type: "SET_BESKRIVELSE",
                beskrivelse: kommentar,
              })
            }
          />
        </div>
        <section className={`l-section`}>
          <h2>Send indberetning</h2>
          <p>
            Vi har brug for din e-mailadresse for at kunne give dig besked om
            behandlingen af din indberetning og for at kunne kontakte dig, hvis
            vi eventuelt har spørgsmål til din indberetning eller har brug for
            flere oplysninger. Hvis du tidligere har indberettet, vil din e-mail
            allerede være udfyldt. Du kan rette din e-mailadresse i det udfyldte
            felt, hvis der er brug for det.
          </p>
          <div className="row g-3 mb-3">
            <EmailInput
              ref={emailRef}
              email={indberetning.email}
              setEmail={(email) => {
                setIndberetning({ type: "SET_EMAIL", email });
              }}
              notifyValidationWarning={indberetning.validation.email.notify}
              resetNotifyValidationWarning={() =>
                setIndberetning({ type: "RESET_NOTIFY_EMAIL" })
              }
              disableForm={false}
              showValidationWarning={
                indberetning.validation.email.touched &&
                !indberetning.validation.email.valid
              }
              validationWarningMessage={indberetning.validation.email.message}
            />
            <AccepterVilkår
              notify={indberetning.validation.vilkårAccepteret.notify}
              resetNotify={() =>
                setIndberetning({
                  type: "RESET_NOTIFY_VILKAAR_ACCEPTERET",
                })
              }
              disabled={false}
              checked={indberetning.vilkårAccepteret}
              setChecked={(checked) => {
                setIndberetning({
                  type: "SET_VILKAAR_ACCEPTERET",
                  vilkårAccepteret: checked,
                });
              }}
              showValidationWarning={
                indberetning.validation.vilkårAccepteret.touched &&
                !indberetning.validation.vilkårAccepteret.valid
              }
              validationWarningMessage={
                indberetning.validation.vilkårAccepteret.message
              }
              showModal={showModal}
              closeModal={closeModal}
            />
            <div className="col-12">
              <button
                onClick={() => {
                  async function post() {
                    try {
                      const i = await postIndberetning(
                        authState.currentUser.personId,
                        indberetning.email,
                        prepareDeleteStednavnSag(indberetning)
                      );
                      if (i.id) {
                        if (authState.currentUser.email !== indberetning.email)
                          setCurrentUserEmail(indberetning.email);
                        toast({
                          content: {
                            message:
                              "Tak for din indberetning, her kan du se en opsummering af din indberetning",
                          },
                        });
                        history.push(
                          routes.stednavnIndberetning.path.replace(
                            ":id",
                            i.id.toString()
                          )
                        );
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }
                  setIndberetning({ type: "SET_ALL_TOUCHED" });
                  if (!indberetning.validation.emne.valid) {
                    findStednavnRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (!indberetning.validation.beskrivelse.valid) {
                    kommentarRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (!indberetning.validation.email.valid) {
                    emailRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (!indberetning.validation.vilkårAccepteret.valid) {
                    kommentarRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else {
                    post();
                  }
                }}
                type="button"
                className="btn btn-lg btn-primary mt-4"
              >
                Indsend indberetning
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
