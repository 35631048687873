import { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { paths } from "routes/routesConfig";
// import { baselayers } from "config/FipConfig";

export default function Stednavn() {
  const titelRef = useRef<HTMLHeadingElement>(null);
  useLayoutEffect(() => {
    titelRef.current?.focus();
  }, []);
  return (
    <div className="mt-5">
      {/* <Link to={paths.kort.path}>Gå til FIP</Link> */}
      <h1 tabIndex={-1} ref={titelRef}>
        Indberet til Danske Stednavne
      </h1>
      <p className="lead me-md-6">
        Her kan du indberette nye stednavne og komme med ændringer til
        eksisterende.
      </p>
      <p>
        Registret Danske Stednavne er oprettet for at indeholde alle stednavne,
        der kan gavne den offentlige og private administration og kommunikation.
        Danske Stednavne indeholder både navne, der kan ses på et kort, og
        navne, der kun anvendes ved søgning f.eks. ved ruteberegning.
      </p>
      <h2 className="h4">Vigtigt inden du indberetter</h2>
      <ul className="mb-4">
        <li>
          Du skal logge på med MitID for at sikre, at registrerede danske
          stednavne forbliver troværdige.
        </li>
        <li>
          Vi beder om din email i forbindelse med at du indsender din
          indberetning for at kunne kontakte dig med eventuelle spørgsmål.
        </li>
        <li>
          Inden du indberetter et nyt stednavn, bør du først undersøge, om
          stednavnet allerede findes. Det kan du gøre ved at søge på stednavnet
          i feltet "Søg".
        </li>
        <li>
          Når du har indberettet et stednavn, vil det hurtigst muligt blive
          registreret og udstillet.
        </li>
      </ul>
      <p>
        Du kan læse om behandling af dine personoplysninger i vores&nbsp;
        <a
          href="https://confluence.sdfi.dk/x/XgLiCQ"
          target="_blank"
          rel="noreferrer"
        >
          privatlivspolitik
        </a>
        .
      </p>
      <div className="card card--sdfe mt-3 mb-4" role="group">
        <div className="card-body">
          <span className="card-title">Mulige handlinger</span>
          <div className="d-flex">
            <Link to={paths.stednavnIndberet} className="btn btn-primary">
              Indberet
            </Link>
            <Link
              to={paths.stednavnIndberetninger}
              className="btn btn-primary ms-2"
            >
              Se oversigt over indberetninger
            </Link>
          </div>
        </div>
      </div>
      <h2 className="h4">Vedligeholdelse af Danske Stednavne</h2>
      <p>
        Danske Stednavne vedligeholdes af Klimadatastyrelsen.
        Danske Stednavne kan kontaktes på{" "}
        <a href="mailto:danskestednavne@kds.dk">danskestednavne@kds.dk</a>.
      </p>
    </div>
  );
}
