export default function Skat() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.75em"
      height="auto"
      viewBox="0 0 461.04 461.039"
      style={{ marginRight: ".75em" }}
    >
      <path d="M0,230.52C0,357.84,103.21,461.04,230.52,461.04s230.52-103.2,230.52-230.52S357.83,0,230.52,0,0,103.21,0,230.52Z" fill="#1e6791" />
      <path d="M326.13,187.05c-3.25-20.65-19.8-32.49-45.5-32.49a68.5,68.5,0,0,0-29.5,6.85c.98-11.05-7.01-19.45-15.87-21.55V127.4a4.74,4.74,0,1,0-9.48,0v12.46c-8.86,2.09-16.85,10.49-15.87,21.55a68.5,68.5,0,0,0-29.5-6.85c-25.7,0-42.25,11.83-45.5,32.49-22.78,2.4-34.2,15.92-33.03,39.14.89,17.69,23.25,46.2,36.51,61.62l.22.26H151.2l-1.12-1.25a309.182,309.182,0,0,1-25.37-32.25c-8.43-12.56-13.05-22.54-13.36-28.86-.48-9.66,1.56-16.98,6.08-21.74,3.6-3.8,9.2-6.25,16.64-7.29v.36a48.534,48.534,0,0,0,.1,5.61c.78,8.81,5.09,20.49,12.8,34.7,3.76,6.92,14.57,25.69,33.78,50.43l.23.29h12.01l-.99-1.22c-.45-.56-45.49-56.28-48.35-84.92-.11-.64-2.56-15.87,6.63-26.74,6.24-7.38,16.38-11.13,30.14-11.13,25.85,0,43.39,18.08,45.37,20.22v103.8h9.49V184.28c1.98-2.14,19.52-20.22,45.37-20.22,13.75,0,23.89,3.74,30.13,11.12,9.19,10.86,6.74,26.11,6.62,26.81-2.85,28.57-47.88,84.3-48.34,84.86l-.99,1.22h12.01l.23-.29c19.2-24.74,30.02-43.51,33.77-50.43,7.71-14.21,12.02-25.88,12.81-34.7a48.669,48.669,0,0,0,.1-5.62v-.35c7.43,1.04,13.03,3.49,16.63,7.29,4.52,4.76,6.57,12.08,6.09,21.74-.32,6.32-4.94,16.3-13.37,28.86a309.183,309.183,0,0,1-25.37,32.25l-1.12,1.25h12.59l.22-.26c13.25-15.42,35.62-43.93,36.51-61.62,1.17-23.22-10.25-36.74-33.03-39.14h-.01Zm-83.7-26.98a11.89,11.89,0,1,1-11.89-11.91A11.909,11.909,0,0,1,242.43,160.07Z" fill="#fff" />
      <path d="M230.52,108.09a4.754,4.754,0,0,0,4.75-4.75V87.35a4.75,4.75,0,0,0-9.5,0v15.99A4.754,4.754,0,0,0,230.52,108.09Z" fill="#fff" />
      <path d="M202.53,120.13h16.01a4.75,4.75,0,0,0,0-9.5H202.53a4.75,4.75,0,0,0,0,9.5Z" fill="#fff" />
      <path d="M242.51,120.13h16.01a4.75,4.75,0,0,0,0-9.5H242.51a4.75,4.75,0,0,0,0,9.5Z" fill="#fff" />
      <rect width="185.46" height="9.5" transform="translate(137.79 314.56)" fill="#fff" />
      <rect width="185.46" height="9.5" transform="translate(137.79 330.55)" fill="#fff" />
      <rect width="185.46" height="9.5" transform="translate(137.79 298.57)" fill="#fff" />
    </svg>
  );
}
