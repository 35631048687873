import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import PlusCircleFill from "../../components/icons/PlusCircleFill";
import { useContext } from "react";
import {
  finishedDrawingSource,
  modifySource,
  setDrawingCallback,
} from "config/interactions";
import { ModalContext } from "components/common/Modal/Modal";
import AddDrawingModal from "components/common/Indberet/AddDrawingModal";
import EditDrawingModal from "components/common/Indberet/EditDrawingModal";
import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import {
  validateNyIndberetningForm,
  prepareSag,
  NyIndberetningFormValidation,
} from "./fipUtil";
import { getObjekttyper, postIndberetning, uploadFile } from "api/fip";
import { AuthContext } from "context/AuthProvider";
import { useHistory } from "react-router";
import { routes } from "routes/routesConfig";
import { MapContext } from "context/MapProvider";
import LoadingSpinner from "components/common/LoadingSpinner";
import { AddFileModal } from "components/common/Indberet/AddFileModal";
import FilListItem from "components/common/Indberetning/FilListItem";
import { Tema } from "interfaces/IIndberetning";
import { tema } from "config/FipConfig";
import TemaSelect from "components/common/Indberet/TemaSelect";
import AccepterVilkår from "components/common/Indberet/AccepterVilkaar";
import DrawingListItem from "components/common/Indberetning/DrawingListItem";
import { removeFeatureFromSource, zoomToFeature } from "util/ol";
import DrawInstructions from "components/common/Indberet/DrawInstructions";
import { ToastContext } from "context/ToastProvider";
import EditFileModal from "components/common/Indberet/EditFileModal";
import EmailInput from "components/common/Indberet/EmailInput";
import { uniqueId } from "lodash";
import FileInput from "components/common/FormElements/FileInput";
import ModalConfirmDelete from "components/common/Indberet/ModalConfirmDelete";
import ValidationErrorMessage from "components/common/FormElements/ValidationErrorMessage";

//TODO: Unhardcode plz
const ændringsTyper = [
  { value: 1, text: "Mangler i kortet (Ny)" },
  { value: 2, text: "Har ændret sig (Rettes)" },
  { value: 3, text: "Er for meget i kortet (Slettes)" },
];

export interface Drawing {
  aendringTypeId: number;
  aendringType: string;
  drawing: Feature<Geometry>;
  featureTypeId: number;
  featureType: string;
  beskrivelse: string;
  dato: string;
}
export interface Fil {
  beskrivelse: string;
  dato: string;
  filId: number;
  geometri: Feature<Geometry>;
  originalFilNavn: string;
}
export interface NyIndberetningState {
  vilkårAccepteret: { touched: boolean; value: boolean };
  beskrivelse: { touched: boolean; value: string };
  emne: { touched: boolean; value: string };
  geometri: { touched: boolean; value: Drawing[] };
  fil: { touched: boolean; value: Fil[] };
  tema: { touched: boolean; value: Tema | undefined };
  email: { touched: boolean; value: string };
}
export interface NewFileState {
  state: "IDLE" | "UPLOADING" | "UPLOADED" | "ERROR";
  file: undefined | { id: string; originalFilNavn: string };
  error?: undefined | string;
}
export default function FipIndberet() {
  const { authState, setCurrentUserEmail } = useContext(AuthContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { dispatchToMap, mapState } = useContext(MapContext);
  const toast = useContext(ToastContext);
  const { activeDrawTool } = mapState;
  const history = useHistory();
  const [newFile, setNewFile] = useState<NewFileState>({
    state: "IDLE",
    file: undefined,
  });
  const temaSelectRef = useRef<HTMLSelectElement>(null);
  const fileInputRef = useRef<HTMLLabelElement>(null);
  const emneSectionRef = useRef<HTMLElement>(null);
  const filSectionRef = useRef<HTMLElement>(null);
  const sendSectionRef = useRef<HTMLElement>(null);
  const emailRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    return () => {
      dispatchToMap({ type: "SET_DRAWTOOL", drawTool: undefined });
      dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
    };
  }, [dispatchToMap]);
  const [objekttyper, setObjekttyper] = useState<
    { value: number; text: string }[]
  >([]);
  const [indberetningValidation, setIndberetningValidation] =
    useState<NyIndberetningFormValidation>({
      form: false,
      fields: {
        tema: { message: "Tema skal udfyldes", notify: false },
        beskrivelse: { message: "Beskrivelse skal udfyldes", notify: false },
        emne: { message: "Emne skal udfyldes", notify: false },
        filEllerGeometri: {
          message: "Indberetning skal indeholde mindst én fil eller geometri",
          notify: false,
        },
        vilkårAccepteret: {
          message: "Betingelserne skal være accepteret",
          notify: false,
        },
        email: { message: "Email skal udfyldes", notify: false },
      },
    });
  const resetValidationNotifies = () => {
    setIndberetningValidation({
      ...indberetningValidation,
      fields: {
        ...indberetningValidation.fields,
        beskrivelse: {
          ...indberetningValidation.fields.beskrivelse,
          notify: false,
        },
        email: {
          ...indberetningValidation.fields.email,
          notify: false,
        },
        emne: {
          ...indberetningValidation.fields.emne,
          notify: false,
        },
        filEllerGeometri: {
          ...indberetningValidation.fields.filEllerGeometri,
          notify: false,
        },
        tema: {
          ...indberetningValidation.fields.tema,
          notify: false,
        },
        vilkårAccepteret: {
          ...indberetningValidation.fields.vilkårAccepteret,
          notify: false,
        },
      },
    });
  };
  const indberetningReducer = (
    state: NyIndberetningState,
    action:
      | { type: "SET_ALL_TOUCHED" }
      | { type: "SET_VILKAAR_ACCEPTERET"; vilkårAccepteret: boolean }
      | { type: "SET_BESKRIVELSE"; beskrivelse: string }
      | { type: "SET_EMNE"; emne: string }
      | { type: "SET_EMAIL"; email: string }
      | { type: "SET_TEMA"; tema: Tema }
      | {
        type: "ADD_FILE";
        fileInfo: {
          beskrivelse: string;
          filId: number;
          geometri: Feature<Geometry>;
          originalFilNavn: string;
        };
      }
      | { type: "UPDATE_FILE"; index: number; beskrivelse: string }
      | { type: "REMOVE_FILE"; fileIndex: number }
      | {
        type: "ADD_DRAWING";
        drawingInfo: {
          aendringTypeId: number;
          aendringType: string;
          drawing: Feature<Geometry>;
          featureTypeId: number;
          featureType: string;
          beskrivelse: string;
        };
      }
      | {
        type: "UPDATE_DRAWING";
        index: number;
        drawingInfo: {
          aendringTypeId: number;
          aendringType: string;
          drawing: Feature<Geometry>;
          featureTypeId: number;
          featureType: string;
          beskrivelse: string;
        };
      }
      | { type: "REMOVE_DRAWING"; drawingIndex: number }
  ): NyIndberetningState => {
    dispatchToMap({ type: "SET_DRAWTOOL", drawTool: undefined });
    dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
    switch (action.type) {
      case "SET_ALL_TOUCHED":
        return {
          geometri: { ...state.geometri, touched: true },
          fil: { ...state.fil, touched: true },
          emne: { ...state.emne, touched: true },
          vilkårAccepteret: { ...state.vilkårAccepteret, touched: true },
          beskrivelse: { ...state.beskrivelse, touched: true },
          tema: { ...state.tema, touched: true },
          email: { ...state.email, touched: true },
        };
      case "SET_VILKAAR_ACCEPTERET": {
        const i = {
          ...state,
          vilkårAccepteret: {
            ...state.vilkårAccepteret,
            value: action.vilkårAccepteret,
          },
        };
        setIndberetningValidation(validateNyIndberetningForm(i));
        return i;
      }
      case "SET_BESKRIVELSE": {
        const i = {
          ...state,
          beskrivelse: {
            ...state.beskrivelse,
            value: action.beskrivelse,
          },
        };
        setIndberetningValidation(validateNyIndberetningForm(i));
        return i;
      }
      case "SET_EMNE": {
        const i = {
          ...state,
          emne: {
            ...state.emne,
            value: action.emne,
          },
        };
        setIndberetningValidation(validateNyIndberetningForm(i));
        return i;
      }
      case "SET_TEMA": {
        const i = {
          ...state,
          tema: {
            ...state.tema,
            value: action.tema,
          },
        };
        setIndberetningValidation(validateNyIndberetningForm(i));
        return i;
      }
      case "ADD_FILE": {
        const { beskrivelse, filId, geometri, originalFilNavn } =
          action.fileInfo;
        const i = {
          ...state,
          fil: {
            ...state.fil,
            value: [
              ...state.fil.value,
              {
                beskrivelse,
                dato: new Date().toISOString(),
                filId,
                geometri,
                originalFilNavn,
              },
            ],
          },
        };
        setIndberetningValidation(validateNyIndberetningForm(i));
        setNewFile({ state: "IDLE", file: undefined });
        return i;
      }
      case "UPDATE_FILE": {
        const { beskrivelse, index } = action;
        const files = state.fil.value;
        const currentFile = files[index];
        if (currentFile) {
          const updatedFile = {
            ...currentFile,
            beskrivelse,
          };
          files[index] = updatedFile;
        }
        return state;
      }
      case "REMOVE_FILE": {
        const newFilArray = [...state.fil.value];
        newFilArray.splice(action.fileIndex, 1);
        const i = {
          ...state,
          fil: {
            ...state.fil,
            value: newFilArray,
          },
        };
        setIndberetningValidation(validateNyIndberetningForm(i));
        return i;
      }
      case "ADD_DRAWING": {
        const {
          aendringType,
          aendringTypeId,
          beskrivelse,
          drawing,
          featureType,
          featureTypeId,
        } = action.drawingInfo;
        const i = {
          ...state,
          geometri: {
            ...state.fil,
            value: [
              ...state.geometri.value,
              {
                aendringTypeId,
                aendringType,
                drawing,
                featureTypeId,
                featureType,
                beskrivelse,
                dato: new Date().toISOString(),
              },
            ],
          },
        };
        setIndberetningValidation(validateNyIndberetningForm(i));
        return i;
      }
      case "UPDATE_DRAWING": {
        const {
          aendringType,
          aendringTypeId,
          beskrivelse,
          drawing,
          featureType,
          featureTypeId,
        } = action.drawingInfo;
        const drawings = state.geometri.value;
        if (drawings[action.index]) {
          const updatedDrawing = {
            aendringTypeId,
            aendringType,
            drawing,
            featureTypeId,
            featureType,
            beskrivelse,
            dato: new Date().toISOString(),
          };
          drawings[action.index] = updatedDrawing;
        }
        return state;
      }
      case "REMOVE_DRAWING": {
        const newGeometriArray = [...state.geometri.value];
        newGeometriArray.splice(action.drawingIndex, 1);
        const i = {
          ...state,
          geometri: {
            ...state.geometri,
            value: newGeometriArray,
          },
        };
        setIndberetningValidation(validateNyIndberetningForm(i));
        return i;
      }

      case "SET_EMAIL": {
        const i = {
          ...state,
          email: {
            ...state.email,
            value: action.email,
          },
        };
        setIndberetningValidation(validateNyIndberetningForm(i));
        return i;
      }
    }
  };
  const addDrawing = useCallback(
    (drawingInfo: {
      aendringTypeId: number;
      aendringType: string;
      drawing: Feature<Geometry>;
      featureTypeId: number;
      featureType: string;
      beskrivelse: string;
    }) => {
      dispatchToMap({ type: "SET_DRAWTOOL", drawTool: undefined });
      setIndberetning({
        type: "ADD_DRAWING",
        drawingInfo,
      });
    },
    [dispatchToMap]
  );
  const updateDrawing = useCallback(
    (
      i: number,
      drawingInfo: {
        aendringTypeId: number;
        aendringType: string;
        drawing: Feature<Geometry>;
        featureTypeId: number;
        featureType: string;
        beskrivelse: string;
      }
    ) => {
      setIndberetning({
        type: "UPDATE_DRAWING",
        index: i,
        drawingInfo,
      });
    },
    []
  );
  const removeDrawing = (index: number) => {
    const geometri = indberetning.geometri.value[index];
    removeFeatureFromSource(geometri.drawing, finishedDrawingSource);
    removeFeatureFromSource(geometri.drawing, modifySource);
    setIndberetning({ type: "REMOVE_DRAWING", drawingIndex: index });
    dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
  };
  const updateFile = useCallback((i: number, beskrivelse: string) => {
    setIndberetning({
      type: "UPDATE_FILE",
      index: i,
      beskrivelse,
    });
  }, []);

  const removeFil = (index: number) => {
    const fil = indberetning.fil.value[index];
    removeFeatureFromSource(fil.geometri, finishedDrawingSource);
    removeFeatureFromSource(fil.geometri, modifySource);
    setIndberetning({ type: "REMOVE_FILE", fileIndex: index });
  };
  const [indberetning, setIndberetning] = useReducer(indberetningReducer, {
    vilkårAccepteret: { touched: false, value: false },
    beskrivelse: { touched: false, value: "" },
    emne: { touched: false, value: "" },
    geometri: { touched: false, value: [] },
    fil: { touched: false, value: [] },
    tema: { touched: false, value: undefined },
    email: {
      touched: false,
      value: "",
    },
  });

  // Set email when authstate is ready
  useEffect(() => {
    const userEmail = authState.currentUser.email;
    if (userEmail && userEmail !== indberetning.email.value) {
      setIndberetning({ type: "SET_EMAIL", email: userEmail });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);
  useEffect(() => {
    const getObjekttyperForTema = async () => {
      if (indberetning.tema.value) {
        const ot = await getObjekttyper(indberetning.tema.value.domainId);
        setObjekttyper(ot);
      }
    };
    getObjekttyperForTema();
  }, [indberetning.tema.value]);

  useEffect(() => {
    setDrawingCallback((drawing, isValid) => {
      if (!isValid) {
        finishedDrawingSource.removeFeature(drawing);
        toast({
          type: "danger",
          content: {
            header: "Ugyldig geometri",
            message: `Den angivne geometri er ugyldig, hvilket kan skyldes, at geometrien "krydser" sig selv.\n\nTegn venligst en ny geometri.`,
          },
        });
      } else {
        if (newFile.state === "UPLOADED" && newFile.file) {
          showModal({
            title: "Beskriv filindhold",
            content: (
              <AddFileModal
                filId={parseInt(newFile.file.id)}
                originalFilNavn={newFile.file.originalFilNavn}
                geometri={drawing}
                addFile={(
                  beskrivelse: string,
                  filId: number,
                  geometri: Feature<Geometry>,
                  originalFilNavn: string
                ) => {
                  setIndberetning({
                    type: "ADD_FILE",
                    fileInfo: { beskrivelse, filId, geometri, originalFilNavn },
                  });

                  dispatchToMap({ type: "SET_DRAWTOOL", drawTool: undefined });
                }}
              />
            ),
            closeModalCallback: () => {
              finishedDrawingSource.removeFeature(drawing);
            },
          });
        } else {
          showModal({
            title: "Beskriv indtegning",
            content: (
              <AddDrawingModal
                mappedObjektType={drawing.get("fipFeatureTypeId")}
                drawing={drawing}
                submit={(
                  aendringTypeId: number,
                  aendringType: string,
                  drawing: Feature<Geometry>,
                  featureTypeId: number,
                  featureType: string,
                  beskrivelse: string
                ) => {
                  addDrawing({
                    aendringTypeId,
                    aendringType,
                    drawing,
                    featureTypeId,
                    featureType,
                    beskrivelse,
                  });
                }}
                objektTyper={objekttyper}
                ændringsTyper={ændringsTyper}
              />
            ),
            closeModalCallback: () => {
              finishedDrawingSource.removeFeature(drawing);
            },
          });
        }
      }
    });
  }, [
    dispatchToMap,
    indberetning,
    newFile.file,
    newFile.state,
    objekttyper,
    showModal,
    addDrawing,
    toast,
  ]);

  const disableForm = indberetning.tema.value === undefined;
  const temaOptions = [
    { value: "", label: "Vælg tema", disabled: true },
    ...tema.map((t) => {
      return { value: t.id.toString(), label: t.name };
    }),
  ];
  const cancelFileUploadAndDrawing = () => {
    setNewFile({ state: "IDLE", file: undefined });
    dispatchToMap({
      type: "SET_DRAWTOOL",
      drawTool: undefined,
    });
  };
  const emneId = uniqueId("emne-input");
  const beskrivelseId = uniqueId("beskrivelse-input");
  const titelRef = useRef<HTMLHeadingElement>(null);
  useLayoutEffect(() => {
    titelRef.current?.focus();
  }, []);
  return (
    <div className="mt-5">
      <h1 tabIndex={-1} ref={titelRef}>
        Opdateringer i kort
      </h1>
      <p className="lead fw-semibold text-dark">Udfyld din indberetning</p>

      {/* section */}
      <section className="l-section">
        {/* section head */}
        <h2>Hvor har du set noget der skal opdateres?</h2>
        {/* section content - component select */}
        <div className="c-select mb-3">
          <TemaSelect
            isInvalid={
              indberetning.tema.touched &&
              indberetningValidation.fields.tema.message !== ""
            }
            tema={indberetning.tema.value}
            setTema={(temaId) => {
              const t = tema.find((t) => t.id.toString() === temaId);
              if (t) {
                dispatchToMap({
                  type: "SET_LAYERS",
                  baselayers: t.baseLayers,
                  layers: t.layers,
                });
                setIndberetning({ type: "SET_TEMA", tema: t });
                dispatchToMap({ type: "SET_TEMA", tema: t });
              }
            }}
            options={temaOptions.filter((t) => t.value !== "1")}
          />
          <small className="form-text">Det valgte kort vises til højre</small>
        </div>
      </section>

      <section
        className={`l-section ${disableForm && "l-section--disabled"}`}
        ref={emneSectionRef}
      >
        {/* section head */}
        <h2>Beskriv indberetning</h2>
        {/* section content - component input, component description */}
        <div className="c-input-text mb-3">
          <label htmlFor={emneId} className="form-label">
            Emne
            <span
              className="text-danger ms-1"
              aria-label="(Skal udfyldes)"
              title="Skal udfyldes"
            >
              <svg
                className="mt-n1"
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                aria-hidden="true"
                focusable="false"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
              </svg>
            </span>
          </label>
          <input
            aria-required="true"
            disabled={disableForm}
            type="text"
            className={
              indberetning.emne.touched
                ? indberetningValidation.fields.emne.message
                  ? "form-control is-invalid"
                  : // : "form-control is-valid"
                  "form-control"
                : "form-control"
            }
            id={emneId}
            aria-describedby="emailHelp"
            value={indberetning.emne.value}
            onChange={(e) => {
              setIndberetning({ type: "SET_EMNE", emne: e.target.value });
            }}
          />
          <ValidationErrorMessage
            message={indberetningValidation.fields.emne.message}
            notify={indberetningValidation.fields.emne.notify}
            resetNotify={resetValidationNotifies}
            show={
              indberetning.emne.touched &&
              indberetningValidation.fields.emne.message !== ""
            }
          />
        </div>
        <div className="c-textarea mb-3">
          <label htmlFor={beskrivelseId} className="form-label">
            Beskrivelse
            <span
              className="text-danger ms-1"
              aria-label="(Skal udfyldes)"
              title="Skal udfyldes"
            >
              <svg
                className="mt-n1"
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                aria-hidden="true"
                focusable="false"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
              </svg>
            </span>
          </label>
          <textarea
            aria-required="true"
            disabled={disableForm}
            className={
              indberetning.beskrivelse.touched
                ? indberetningValidation.fields.beskrivelse.message
                  ? "form-control is-invalid"
                  : // : "form-control is-valid"
                  "form-control"
                : "form-control"
            }
            id={beskrivelseId}
            value={indberetning.beskrivelse.value}
            onChange={(e) => {
              setIndberetning({
                type: "SET_BESKRIVELSE",
                beskrivelse: e.target.value,
              });
            }}
          ></textarea>
          <ValidationErrorMessage
            message={indberetningValidation.fields.beskrivelse.message}
            show={
              indberetning.beskrivelse.touched &&
              indberetningValidation.fields.beskrivelse.message !== ""
            }
            notify={indberetningValidation.fields.beskrivelse.notify}
            resetNotify={resetValidationNotifies}
          />
        </div>
      </section>
      <div
        className={
          indberetning.fil.touched || indberetning.geometri.touched
            ? indberetningValidation.fields.filEllerGeometri.message
              ? "l-section-wrapper form-control is-invalid "
              : // : "l-section-wrapper form-control is-valid"
              "l-section-wrapper"
            : "l-section-wrapper"
        }
      >
        <section
          className={`l-section ${disableForm && "l-section--disabled"}`}
          ref={filSectionRef}
        >
          <h2>Indtegn din opdatering i kortet</h2>
          <p>
            I kortet til højre skal du lokalisere stedet, som din indberetning
            omhandler. Du kan indtegne det med et punkt, en linje eller en
            polygon. Du kan også vælge at udpege det, som din indberetning
            drejer sig om fx en vej eller en bygning. Du har mulighed for at
            indtegne flere opdateringer, hvis der er behov for det.
          </p>
          <div className="c-draw mb-3">
            {indberetning.geometri.value.length === 0 && (
              <span className="d-block bg-tint-primary text-shade-primary fw-normal py-2 px-3 mb-3">
                Du har ingen opdateringer i kortet.
              </span>
            )}
            {indberetning.geometri.value.length > 0 && (
              <>
                <span className="form-label d-block">
                  Tegnede opdateringer i kortet
                </span>
                <ul
                  className="list-group list-group-focus mb-3"
                  aria-live="assertive"
                >
                  {indberetning.geometri.value.map((geometri, i) => {
                    const id = geometri.drawing.get("id");

                    return (
                      <DrawingListItem
                        key={id}
                        index={i}
                        type="normal"
                        hovered={mapState.hoveredFeatures.indexOf(id) > -1}
                        geometri={geometri}
                        setHoveredFeatures={(ids) => {
                          dispatchToMap({
                            type: "SET_HOVERED_FEATURES",
                            hoveredFeatures: ids,
                          });
                        }}
                        zoomToDrawing={() => {
                          if (mapState.map) {
                            zoomToFeature(mapState.map, geometri.drawing);
                          }
                        }}
                        onEditGeometryClick={() => {
                          cancelFileUploadAndDrawing();
                          dispatchToMap({
                            type: "EDIT_GEOMETRY",
                            geometryToEdit: geometri.drawing,
                          });
                        }}
                        onEditClick={() => {
                          cancelFileUploadAndDrawing();
                          dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
                          showModal({
                            title: "Rediger indtegning",
                            content: (
                              <EditDrawingModal
                                drawing={geometri}
                                submit={(
                                  aendringTypeId: number,
                                  aendringType: string,
                                  drawing: Feature<Geometry>,
                                  featureTypeId: number,
                                  featureType: string,
                                  beskrivelse: string
                                ) => {
                                  updateDrawing(i, {
                                    aendringTypeId,
                                    aendringType,
                                    drawing,
                                    featureTypeId,
                                    featureType,
                                    beskrivelse,
                                  });
                                }}
                                objektTyper={objekttyper}
                                ændringsTyper={ændringsTyper}
                              />
                            ),
                            closeModalCallback: () => { },
                          });
                        }}
                        onDeleteClick={() => {
                          cancelFileUploadAndDrawing();
                          showModal({
                            title: "Slet opdatering",
                            content: (
                              <ModalConfirmDelete
                                type={"opdatering"}
                                confirm={() => {
                                  removeDrawing(i);
                                }}
                                closeModal={closeModal}
                              />
                            ),
                          });
                        }}
                      />
                    );
                  })}
                </ul>
              </>
            )}

            <div className="d-flex align-items-center mt-2">
              <button
                disabled={disableForm}
                type="button"
                className={`btn btn-primary ${activeDrawTool && !newFile.file ? "btn-danger text-white" : ""
                  }`}
                onClick={() => {
                  if (!activeDrawTool || newFile.file) {
                    // Aktiver tegning
                    dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
                    setNewFile({ state: "IDLE", file: undefined });
                    dispatchToMap({
                      type: "SET_DRAWTOOL",
                      drawTool: "polygon",
                    });
                  } else {
                    // Fortryd tegning
                    dispatchToMap({
                      type: "SET_DRAWTOOL",
                      drawTool: undefined,
                    });
                  }
                }}
              >
                {(!activeDrawTool || newFile.file) && (
                  <>
                    <PlusCircleFill class="me-2" />
                    Tegn opdatering
                  </>
                )}
                {activeDrawTool && !newFile.file && (
                  <>
                    <PlusCircleFill class="me-2 rotate-45" />
                    Fortryd tegning
                  </>
                )}
              </button>
              <div className="ms-3 d-none" aria-hidden="true">
                {activeDrawTool && !newFile.file && (
                  <DrawInstructions activeDrawTool={activeDrawTool} />
                )}
              </div>
            </div>
          </div>
          {indberetning.tema.value === undefined && (
            <div className="text-shade-primary">
              <i>Vælg et korttema inden du tegner i kortet.</i>
            </div>
          )}
        </section>

        {/* section */}
        <section
          className={`l-section ${disableForm && "l-section--disabled"}`}
        >
          {/* section head */}
          <h2>Upload fil</h2>
          <p>
            Det er frivilligt om du vil tilføje filer til din opdatering, fx
            billeder af stedet, gis-filer eller en pdf, der viser dit
            ændringsforslag.
          </p>
          {indberetning.fil.value.length === 0 && (
            <span className="d-block bg-tint-primary text-shade-primary fw-normal py-2 px-3 mb-3">
              Du har ikke vedlagt nogle filer
            </span>
          )}
          {indberetning.fil.value.length > 0 && (
            <>
              <span className="form-label d-block">Vedlagte filer</span>
              <ul className="list-group list-group-focus mb-3">
                {indberetning.fil.value.map((fil, i) => {
                  const id = fil.geometri.get("id");
                  return (
                    <FilListItem
                      type="normal"
                      key={i}
                      fil={fil}
                      id={id}
                      index={i}
                      hoveredFeatures={mapState.hoveredFeatures}
                      onClick={() => {
                        if (mapState.map) {
                          zoomToFeature(mapState.map, fil.geometri);
                        }
                      }}
                      onMouseEnter={() =>
                        dispatchToMap({
                          type: "SET_HOVERED_FEATURES",
                          hoveredFeatures: [id],
                        })
                      }
                      onMouseLeave={() => {
                        dispatchToMap({
                          type: "SET_HOVERED_FEATURES",
                          hoveredFeatures: [],
                        });
                      }}
                      removeFil={() => removeFil(i)}
                      onEditGeometryClick={() => {
                        cancelFileUploadAndDrawing();
                        dispatchToMap({
                          type: "EDIT_GEOMETRY",
                          geometryToEdit: fil.geometri,
                        });
                      }}
                      onEditClick={() => {
                        showModal({
                          title: "Rediger filindhold",
                          content: (
                            <EditFileModal
                              currentBeskrivelse={fil.beskrivelse}
                              updateFile={(beskrivelse: string) => {
                                updateFile(i, beskrivelse);
                              }}
                            />
                          ),
                          closeModalCallback: () => { },
                        });
                      }}
                      showModal={showModal}
                      closeModal={closeModal}
                    />
                  );
                })}
              </ul>
            </>
          )}
          {/* section content - component draw */}

          {(newFile.state === "IDLE" || newFile.state === "ERROR") && (
            <>
              {newFile.state === "ERROR" && (
                <div
                  className="alert alert-danger d-flex align-items-center"
                  role="alert"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-exclamation-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                  </svg>
                  <div>{newFile.error}</div>
                </div>
              )}

              <FileInput
                ref={fileInputRef}
                disabled={disableForm}
                onChange={async (e) => {
                  dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
                  setNewFile({ state: "UPLOADING", file: undefined });
                  const { files } = e.target;
                  if (files && files.length) {
                    const file = files[0];
                    let formData = new FormData();
                    formData.append("file", file);
                    uploadFile(formData)
                      .then((resp) => {
                        setNewFile({
                          state: "UPLOADED",
                          file: {
                            id: resp.id,
                            originalFilNavn: resp.originalFilNavn,
                          },
                        });
                        dispatchToMap({
                          type: "SET_DRAWTOOL",
                          drawTool: "polygon",
                        });
                      })
                      .catch((e) => {
                        let errorMsg = "Der skete en fejl";
                        if (e.isAxiosError && e.response?.status === 413) {
                          errorMsg = "Filen er for stor.. (max 10MB)";
                        }
                        setNewFile({
                          state: "ERROR",
                          file: undefined,
                          error: errorMsg,
                        });
                      });
                  }
                }}
              />
            </>
          )}

          {newFile.state === "UPLOADING" && <LoadingSpinner />}
          {newFile.state === "UPLOADED" && newFile.file && (
            <>
              <div
                className="alert alert-primary bg-transparent border-2"
                role="alert"
              >
                <h4 className="alert-heading">
                  <strong>Afslut upload:</strong> Placer filen i kortet
                </h4>
                <p>
                  Filen <strong>{newFile.file.originalFilNavn}</strong> skal
                  tilknyttes en placering i kortet før din upload er afsluttet.
                </p>
                <div className="mt-2 gap-2 d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-danger text-white"
                    onClick={() => cancelFileUploadAndDrawing()}
                  >
                    <PlusCircleFill class="me-2 rotate-45" />
                    Fortryd upload
                  </button>
                  <DrawInstructions activeDrawTool={activeDrawTool} />
                </div>
              </div>
            </>
          )}
        </section>
      </div>
      <ValidationErrorMessage
        message={indberetningValidation.fields.filEllerGeometri.message}
        notify={indberetningValidation.fields.filEllerGeometri.notify}
        resetNotify={() => {
          resetValidationNotifies();
        }}
        show={
          indberetning.fil.touched &&
          indberetning.geometri.touched &&
          indberetningValidation.fields.filEllerGeometri.message !== ""
        }
      />
      {/* section */}
      <section
        className={`l-section ${disableForm && "l-section--disabled"}`}
        ref={sendSectionRef}
      >
        {/* section head */}
        <h2>Send indberetning</h2>
        <p>
          Vi har brug for din e-mailadresse for at kunne give dig besked om
          behandlingen af din indberetning og for at kunne kontakte dig, hvis vi
          eventuelt har spørgsmål til din indberetning eller har brug for flere
          oplysninger. Hvis du tidligere har indberettet, vil din e-mail
          allerede være udfyldt. Du kan rette din e-mailadresse i det udfyldte
          felt, hvis der er brug for det.
        </p>
        {/* section content - component draw */}
        <div className="row g-3 mb-3">
          <EmailInput
            disableForm={disableForm}
            email={indberetning.email.value}
            notifyValidationWarning={indberetningValidation.fields.email.notify}
            resetNotifyValidationWarning={resetValidationNotifies}
            setEmail={(email) => {
              setIndberetning({
                type: "SET_EMAIL",
                email,
              });
            }}
            showValidationWarning={
              indberetningValidation.fields.email.message !== ""
            }
            validationWarningMessage={
              indberetningValidation.fields.email.message
            }
            ref={emailRef}
          />
          <AccepterVilkår
            notify={indberetningValidation.fields.vilkårAccepteret.notify}
            resetNotify={resetValidationNotifies}
            checked={indberetning.vilkårAccepteret.value}
            setChecked={(checked) => {
              setIndberetning({
                type: "SET_VILKAAR_ACCEPTERET",
                vilkårAccepteret: checked,
              });
            }}
            disabled={disableForm}
            showValidationWarning={
              indberetning.vilkårAccepteret.touched &&
              indberetningValidation.fields.vilkårAccepteret.message !== ""
            }
            validationWarningMessage={
              (indberetningValidation.fields.vilkårAccepteret.message = "")
            }
            showModal={showModal}
            closeModal={closeModal}
          />
          <div className="col-12">
            <button
              disabled={disableForm}
              onClick={() => {
                async function post() {
                  try {
                    if (!indberetning.tema.value)
                      throw new Error("tema ikke sat");
                    const i = await postIndberetning(
                      authState.currentUser.personId,
                      indberetning.email.value,
                      prepareSag(indberetning, indberetning.tema.value)
                    );
                    if (i.id) {
                      if (
                        authState.currentUser.email !== indberetning.email.value
                      )
                        setCurrentUserEmail(indberetning.email.value);
                      toast({
                        content: {
                          message:
                            "Tak for din indberetning, her kan du se en opsummering af din indberetning",
                        },
                      });
                      history.push(
                        routes.kortIndberetning.path.replace(
                          ":id",
                          i.id.toString()
                        )
                      );
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }
                if (mapState.activeDrawTool) {
                  toast({
                    type: "danger",
                    content: {
                      header: "Tegner opdatering",
                      message:
                        "Gør færdig opdateringen i kortet inden du sender indberetningen ind.",
                    },
                  });
                } else if (mapState.editGeometryState.active) {
                  toast({
                    type: "danger",
                    content: {
                      header: "Redigerer opdatering",
                      message:
                        "Gør færdig redigeringen i kortet inden du sender indberetningen ind.",
                    },
                  });
                } else if (!indberetningValidation.form) {
                  setIndberetning({ type: "SET_ALL_TOUCHED" });
                  const {
                    tema,
                    emne,
                    beskrivelse,
                    filEllerGeometri,
                    vilkårAccepteret,
                    email,
                  } = indberetningValidation.fields;
                  if (tema.message && temaSelectRef.current) {
                    temaSelectRef.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (
                    (emne.message || beskrivelse.message) &&
                    emneSectionRef.current
                  ) {
                    emneSectionRef.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (
                    filEllerGeometri.message &&
                    filSectionRef.current
                  ) {
                    filSectionRef.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (email.message && emailRef.current) {
                    emailRef.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (
                    vilkårAccepteret.message &&
                    sendSectionRef.current
                  ) {
                    sendSectionRef.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                  setIndberetningValidation(
                    validateNyIndberetningForm(indberetning)
                  );
                } else {
                  post();
                }
              }}
              type="button"
              className="btn btn-lg btn-primary mt-4"
            >
              Indsend indberetning
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
