import { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { paths } from "routes/routesConfig";
export default function Fip() {
  const titelRef = useRef<HTMLHeadingElement>(null);
  useLayoutEffect(() => {
    titelRef.current?.focus();
  }, []);
  return (
    <div className="mt-5">
      <h1 tabIndex={-1} ref={titelRef}>
        Indberet opdateringer i kort
      </h1>
      <h2 className="lead me-md-6">
        Fortæl os om opdateringer til kort og data
      </h2>
      <p>
        Her kan du give os besked om fejl, mangler og ændringsforslag til et af
        Klimadatastyrelsens (KDS) kort.
      </p>
      <div className="card card--sdfe mt-3 mb-4" role="group">
        <div className="card-body">
          <span className="card-title">Mulige handlinger</span>
          <div className="d-flex">
            <Link to={paths.kortIndberet} className="btn btn-primary">
              Indberet
            </Link>
            <Link
              to={paths.kortIndberetninger}
              className="btn btn-primary ms-2"
            >
              Se oversigt over indberetninger
            </Link>
          </div>
        </div>
      </div>
      <h2 className="h4">For at indberette har vi brug for MitID og email</h2>
      <p>
        For at indberette skal du logge ind med dit MitID og du blive bedt om
        din e-mailadresse.
      </p>
      <p>
        Vi beder om din e-mailadresse for at kunne give dig besked om
        behandlingen af din indberetning og for at kunne kontakte dig, hvis vi
        eventuelt har spørgsmål til din indberetning eller har brug for flere
        oplysninger.
      </p>
      <p>
        Du kan læse om behandling af dine personoplysninger i vores&nbsp;
        <a
          href="https://confluence.sdfi.dk/x/XgLiCQ"
          target="_blank"
          rel="noreferrer"
        >
          privatlivspolitik
        </a>
        .
      </p>
      <h2 className="h4 mt-4">Hvad kan en indberetning indeholde?</h2>
      <p>
        Med en indberetning kan du fortælle os om fejl, mangler eller ændringer
        til Klimadatastyrelsens kort og data f.eks. nye bygninger/tilbygning, vindmøller, veje
        eller navne, der findes i den fysiske verden men ikke i kortet.
      </p>
      <p>
        <strong>Det er muligt at rapportere indenfor:</strong>
      </p>
      <ul className="mb-4">
        <li>Landkort (topografiske kort)</li>
        <li>Danmarks Højdemodel</li>
        <li>GeoDanmark</li>
      </ul>
    </div>
  );
}
